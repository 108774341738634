.carousel-control-background {
    background-size: cover;
    background-position: bottom right;
    height: auto;

    width: 150em;
    max-width: 100%;
}

.my-slide-wrapper {
    background: white;
}

.slide-content {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #FFF;
    display: flex;
    margin-left: 5%;
    z-index: 10;
    justify-content: center;
    flex-flow: column;

    @media (max-width: 500px) {
        font-size: .8em;
    }

    .slide-title {
        font-size: 3em;
        line-height: normal;
        margin-bottom: .5rem;
        font-weight: 600;
        @media (max-width: 1600px) {
            font-size: 1.5em;
        }
        @media (max-width: 1200px) {
            font-size: 14px;
        }
    }

    .slide-description {
        font-size: 16px;
        margin-block: 1em;
        line-height: 26px;
        @media (max-width: 1200px) {
            display: none;
        }
    }

    .slide-cta {
        margin-top: 1em;

        a {
            color: #FFF !important;
            padding: 0;
            margin: 0;
            font-size: 18px;
            font-weight: bold;
        }

        a:after {
            font: var(--fa-font-solid);
            content: "\f178";
            display: inline-block;
            margin-left: .5em;
            width: 1em;
            vertical-align: middle;
        }
    }
}

.arrows {
    bottom: 0;
    right: 0;
    color: #FFF;
    margin-bottom: 3em;
    margin-right: 2em;
    @media (max-width: 800px) {
        margin-bottom: 2em;
        margin-right: 1em;
    }

    i {
        font-size: 34px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .5s ease-in-out;
        @media (max-width: 800px) {
            font-size: 3em;
        }

    }

    i:hover {
        opacity: .5;
    }
}
.slick-dots {
    li {
        button {
            background: $green;
            font-size: 0;
            margin-bottom: 4px;
        }
    }
}
