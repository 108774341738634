/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000007735e609
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-05-12 07:48:42 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=rls7yxi&ht=tk&f=175&a=8194263&app=typekit&e=css");

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }
/* quicksand-regular - latin-ext_latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/quicksand-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/quicksand-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/quicksand-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/quicksand-v29-latin-ext_latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
  }
  /* quicksand-600 - latin-ext_latin */
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/quicksand-v29-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/quicksand-v29-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/quicksand-v29-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/quicksand-v29-latin-ext_latin-600.svg#Quicksand') format('svg'); /* Legacy iOS */
  }
  /* quicksand-700 - latin-ext_latin */
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/quicksand-v29-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/quicksand-v29-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/quicksand-v29-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/quicksand-v29-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/quicksand-v29-latin-ext_latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
  }
/* Book Antiqua - latin-ext_latin */
@font-face {
    font-family: 'Book Antiqua';
    font-style: normal;
    font-weight: normal;
    src: local(''),
         url('../fonts/Book Antiqua.ttf') format('truetype')
  }
  @font-face {
    font-family: 'Book Antiqua';
    font-style: normal;
    font-weight: bold;
    src: local(''),
         url('../fonts/Book Antiqua Bold.ttf') format('truetype')
  }

  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
  @import '~@fortawesome/fontawesome-free/scss/fontawesome';
  @import '~@fortawesome/fontawesome-free/scss/solid';
  @import '~@fortawesome/fontawesome-free/scss/regular';
  @import '~@fortawesome/fontawesome-free/scss/brands';

// Body
$body-bg: #f8fafc;
$dm-card-body-light: #8b8b8b;
$dm-card-body: #1e2026;
$dm-card-header: #1c2329;
$dm-page-title: #161a1e;
$dm-bg: rgb(52, 58, 64);

$hc-text: #deff00;
$hc-back: #000000;
$hc-pink: #f33bee;
$hc-grey: #1a1a1a;
$hc-grey-2: #aaaaaa;
$box-shadow: #fff;

// Typography
$font-family-sans-serif: 'Book Antiqua', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$dm-font-color: rgba(255, 255, 255, 0.7);
$dm-font-color-light: rgba(255, 255, 255);
$dm-font-color-dark: rgba(220, 220, 220, 0.7);

$dm-link-color: #FFF;
$dm-link-color-active: #7d9eff;

$L-font-size: 1.25rem;
$XL-font-size: 1.5rem;


// Colors
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$teal: #20c997;
$cyan: #17a2b8;
$gray-200: #e9ecef !default;
$gray-600: #8e8e8e !default;

$secondary: #646464;

//edited
$green: #5c9f09;
$red: #f20c0c;
$blue: #1164ed;
$orange: #f2580c;
$lightblue: #11a7ed;
$yellow: #e5bc18;


$border-radius: .25rem !default;

// Breadcrumbs

$breadcrumb-font-size: null !default;

$breadcrumb-padding-y: .75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: .5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg: #FFF !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $green !default;
$breadcrumb-divider: "\f105";

$breadcrumb-border-radius: $border-radius !default;
