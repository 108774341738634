.procurements {
    &>div {
        &>div {
            background-color: #f7f7f7;
            font-weight: 700 !important;
            width: 100%;

            p {
                color: #000;
                font-size: 23px;
                @media (max-width: 536px) {
                    font-size: 16px;
                }
                a {
                    color: #000;
                }
            }

            h3 {
                color: $red;
                font-size: 16px;
                font-weight: 700;
                margin-block: 1.5em;
                a {
                    color: $red;
                }
            }

            border-bottom: 3px solid $red;
        }
    }

    .public-procurement-tender {
        &>div {
            h3 {
                color: $blue;
            }

            border-color: $blue;

        }
    }

}

.news {
    &>div {
        &>div {
            box-shadow: 0 0 35px rgba(0, 0, 0, .1);

        }

        color: #000;
        font-weight: 700;


        p {
            margin-top: 0;
            font-size: 23px;

            @media (max-width: 536px) {
                font-size: 16px;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: 700;
            margin-block: 1.5em;
        }

        &:nth-child(3n+1) {
            h3 {
                color: $orange;
            }
        }

        &:nth-child(3n+2) {
            h3 {
                color: $lightblue;
            }
        }

        &:nth-child(3n) {
            h3 {
                color: $yellow;
            }
        }
    }

}
