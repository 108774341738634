.divider-bottom-green {
    margin-block: 2em;
    border-bottom: $green solid 5px;
    border-radius: 5px;
    width: 9em;
}

.divider-bottom-green-small {
    margin-top: 1em;
    margin-bottom: 2em;
    border-bottom: $green solid 3px;
    border-radius: 5px;
    width: 5em;
}