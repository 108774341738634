.footer-nav {
    background-color: #fff;
    padding: 0 5em;
    padding-top: 10em;
    @media (max-width: 760px) {
        padding: 0 2em;
    }

    a,
    .footer-adress {
        padding: 0;
        font-size: 1em;
        font-weight: 700 !important;
        margin: .2em 0 .2em 1em;
        color: #646464;
    }
    .footer-adress {
        font-size: 1.075em;
    }

    a:hover {
        color: #000;
    }

    h3 {
        font-size: 24px;
        color: #000000;
        font-weight: 700;
    }

    img {
        margin-bottom: 1.5em;
    }
}

.footer-policy {
    background-color: #c9c7c7;
    padding-right: 3em;

    a {
        color: #000;
        font-weight: 700;
        font-size: 20px;

        @media (max-width: 760px) {
            font-size: 14px;
        }
    }

    a:hover {
        text-decoration: none;
        color: #646464;
    }

    a:first-child {
        border-right: #000 solid 1px !important;
        border-radius: 0;
        @media (max-width: 362px) {
            border-right: none !important;
            border-bottom: #000 solid 1px;
        }
    }
}
