.search-result {
    box-shadow: 0 0 35px rgba(0, 0, 0, .1);
    margin-bottom: 1em;
    color: #000;
    font-family: "Book Antiqua";
    font-weight: 700;

    div:first-child {
        font-size: 1.4375em;
        padding: 1em;

    }

    a {
        background-color: $green;
        font-size: 18px;
        text-decoration: none;
        height: 100%;
        color: #FFF;
        transition: all .5s ease-in-out;
    }

    a:hover {
        background-color: #FFF;

        &,
        &:after {
            color: $green
        }
    }

    a:after {
        color: #FFF;
    }
}

.results-controls {
    margin-top: 3em;
    font-weight: 700;
    font-family: 'Book Antiqua';

    div > * {
        flex: 1 0 auto;
    }
    .result-pages {
        font-size: 1.375em;
    }

    .result-page-prev {
        margin-left: 2em;
    }

    .result-page-prev.disabled,
    .result-page-next.disabled,
    .result-page-prev.disabled:hover,
    .result-page-next.disabled:hover {
        cursor: auto;

        &::before {
            color: #777777;
        }

    }

    .result-page-prev:hover,
    .result-page-next:hover {
        &::before {
            color: $green;
        }
    }

    .result-page-prev:before,
    .result-page-next:before {
        font: var(--fa-font-solid);
        font-size: 1.5em;
        content: "\f0a8";
        display: inline-block;
        margin-right: .5em;
        vertical-align: middle;
        font-weight: 900;
        transition: all .5s ease-in-out;
        color: #000;
    }

    .result-page-next:before {
        content: "\f0a9";
    }
}
