.contacts-data {
    &>* {
        margin-block: 2em;

        h3 {
            font: 1.4375em 'Book Antiqua';
            color: #000;
            font-weight: 600;
            margin-block: 2em;
        }

        &>div>* {

            display: inline-flex;
            width: 100%;
            color: #777777;
            font-weight: 400;
            font: 1.375em 'proxima-nova';

            &:before {
                font: var(--fa-font-solid);
                width: 2em;
                padding-right: 1em;
                display: flex;
                justify-content: center;
                color: $green;
            }
        }
    }
}
