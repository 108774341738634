// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "front-variables";

// Bootstrap
@import "~flag-icons";
@import "~select2";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css";
@import "~jquery-datetimepicker/jquery.datetimepicker.css";
@import "slider";
@import "footer";
@import "dividers";
@import "contacts";
@import "home";
@import "search";

@mixin inline {
    display: inline-block;
    border-top: 2.5em solid #cecece;
    *display: inline;
    zoom: 1;
    vertical-align: top;
}

body {
    transition: 200ms top ease;
    -webkit-transition: 200ms top ease;
    overflow-x: hidden;
    font-family: 'Book Antiqua';
    margin: auto;
    background-color: #cecece;
    transition: padding-top 0.2s;
    font-size: 16px;


    @media (max-width: 1650px) {
        font-size: 15px;
    }

    @media (max-width: 1400px) {
        font-size: 14px;
    }

    @media (max-width: 800px) {
        padding: 0;
    }
}

.container {
    width: 1750px;
    max-width: 100% !important;
    @media (max-width: 1800px) {
        width: 95%;
    }

    @media (max-width: 800px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%;
    }
}

.navbar-brand {
    padding-top: 0;
}

.logo-brand {
    width: 413px;

    @media (max-width: 1800px) {
        width: 300px;
    }

    @media (max-width: 1540px) {
        width: 206px;
    }

    @media (max-width: 1090px) {
        width: 150px;
    }
}

.title {
    padding-top: 2rem;
    font: 48px 'Book Antiqua';
    font-weight: 700;
    color: #000;
    @media (max-width: 536px) {
        font-size: 28px;
    }
}

.main-navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media (max-width: 1200px) {
        width: 100%;
        .dropdown-toggle-alt::after {
            position: absolute;
            right: 10px;
        }
    }
    @media (min-width: 1201px) {
        .nav-item {
            >.dropdown-toggle-alt:hover {
                display: block;

                ~ .dropdown-menu {
                    display: block;
                }
            }

            >.dropdown-menu:hover {
                display: block;

                ~ .dropdown-toggle-alt {
                    display: block;
                }
            }
        }
    }
}

.round-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    transition: all .5s ease-in-out;

    .pagination-item:first-child,
    .pagination-item:last-child {
        font-size: 2em;
        color: #000;

        &.disabled,
        &.disabled:hover {
            color: #777777;
        }

        &:hover {
            color: $green;
        }
    }

    .pagination-item {
        width: 1.3em;
        height: 1.3em;
        margin-inline: 8px;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination-item.active {
        color: #FFF;
        background-color: $green;
        border-color: $green;
        border-radius: 100%;
        cursor: default;

        &:hover {
            color: #FFF;
        }
    }

    a {
        color: $green;

        &:hover {
            color: #000;
        }
    }
}

.logo-ue {
    display: flex;

    img {
        margin: auto;
        @media (min-width: 800px) {
            margin-right: 1.8em;
        }
    }
}

.app-color {
    color: $green;
}

.subtext {
    font-family: 'Book Antiqua';
    color: #777777;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 2em;

    p {
        color: black;
    }
}

.no-border {
    border: none;
    box-shadow: none;
    padding: 32px;
    background-color: #f4f3f3;
    font-size: 22px;
    @media (max-width: 536px) {
        padding: 22px;
        font-size: 14px;
    }

    &::placeholder {
        color: #000;
        font-family: "Book Antiqua";
        font-weight: 700;

        &::placeholder {
            color: #000;
            font-family: "Book Antiqua";
            font-weight: 700;

        }

        &:hover,
        &:focus,
        &:active,
        &:valid {
            background-color: #f4f3f3;
        }

    }
}

th > a {
    color: black;
}

.right-arrow:after {
    font: var(--fa-font-solid);
    content: "\f178";
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-left: .5em;
    text-decoration: none;
    width: 1em;
    vertical-align: middle;
    color: $green;

}

.side-links {

    & > * {
        @media (max-width: 991px) {
            margin: 0 !important;
            width: 50%;
            padding: 2% !important;
        }

        @media (max-width: 480px) {
            margin: 0 !important;
            padding: 4% !important;
            width: 100%;
        }
    }

    h2 {
        font-size: 48px;
        font-weight: 600;
        color: #000;
        @media (max-width: 536px) {
            font-size: 28px;
        }
    }

    li {
        a {
            color: #777777;
            font-size: 1.5em;
        }

        a:before {
            font: var(--fa-font-solid);
            content: "\f0a9";
            display: inline-block;
            margin-right: 20px;
            vertical-align: middle;
            font-weight: 600;
            font-size: 18px;
            color: $green;
        }
        &.dropdown {
            >a {
                &:before {
                    transition: transform 0.3s;
                }
            }
            &.show {
                >a {
                    &:before {
                        transform: rotateZ(90deg);
                    }
                }
            }
            &:hover {
                background: transparent!important;
                box-shadow: unset!important;
            }
        }
    }
    .dropdown-menu {
        position: relative!important;
        transform: unset!important;
        background: transparent!important;
        box-shadow: unset!important;

        .nav-link:before {
            content: "\f107"!important;
            transform: rotateZ(270deg)!important;
        }
    }
}

.dropdown-toggle-alt::after {
    font: var(--fa-font-solid);
    content: "\f107";
    display: inline-block;
    margin-left: .5em;
    vertical-align: middle;
    color: #8e8e8e;
    font-size: 0.7em;
    padding-top: 4px;
}

.submit-button {
    color: $green;
    border: none;
    font-size: 18px;
}

span[class^="public-procurement-"] {
    color: $blue;
}

.seeall {
    display: flex;
    align-items: center;
    font-weight: 700;

    a {
        color: $green;
        font-size: 18px;
        transition: all .5s ease-in-out;

        &:hover {
            color: #000;
        }

        &::after {
            font: var(--fa-font-solid);
            content: "\f15b";
            display: inline-block;
            margin-left: .5em;
            width: 1em;
            vertical-align: middle;
            color: $green;
        }
    }


}

.breadcrumb {
    padding: 0;
}

.readmore {
    color: $green;
    transition: all .5s ease-in-out;
    font-size: 18px;
    font-weight: 600;

    @media (max-width: 536px) {
        font-size: 14px;
    }

    &:after {
        font: var(--fa-font-solid);
        content: "\f178";
        display: inline-block;
        margin-left: .5em;
        width: 1em;
        vertical-align: middle;
        color: $green;
    }

    &:hover {
        color: inherit;
    }
}

.header {
    li {
        a {
            color: $green;
        }

        border-right: #cbcbcb solid 1px;
        padding-inline: 1.5em;
        padding-block: 0;
        margin-block: 0;
    }

    li:last-child {
        border: none;
        @media(min-width: 1200px) {
            padding-right: 0;
        }
    }

    .fa-search {
        cursor: pointer;
        font-size: 28px;
        color: #777;
    }

    .fis {
        width: 2em;
        height: 1.5em;
        margin-inline: 8px;
        cursor: pointer;
    }
}

.simple-menu .nav-item a {
    color: #000;
    font: 1.375em 'Book Antiqua';
    font-weight: 700;

    &:hover {
        color: #777;
    }

}

.navbar-nav > .active > a {
    color: $green;
}

.hamburger-hide {
    display: flex;

    @media (max-width: 1200px) {
        display: none;
    }
}
.navbar {
    .nav-item {
        .dropdown-menu {
            li {
                ul {
                    li {
                        .nav-link, .dropdown-item {
                            font-weight: normal !important;
                        }
                    }
                }
            }
        }
    }
}
.hamburger-show {
    display: none;

    @media (max-width: 1200px) {
        display: flex;
    }
}

.nav-item.dropdown:hover {
    border: none;
    border-radius: 0;

    @media (min-width: 1200px) {
        box-shadow: 0 0 35px rgba(0, 0, 0, .1);
    }
}

.nav-item.dropdown.show .dropdown-menu {
    left: 0;
    margin-left: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 35px 35px rgba(0, 0, 0, .1);

    @media (max-width: 1200px) {
        box-shadow: none;
    }
}

.nav-item.dropdown .dropdown-menu {
    border: 0 !important;

    .dropdown-menu {
        position: static;
        box-shadow: unset !important;
    }
}

.nav-item {
    .nav-link {
        display: flex;
        border: none;
        align-items: center;
        font-weight: 700 !important;
        color: #000 !important;
        font: 22px 'Book Antiqua';
        height: 100%;
        @media (max-width: 536px) {
            font-size: 14px;
        }
    }


    .nav-link:hover {
        color: $green;
    }
}
.dropdown-menu {
    top: calc(100% - 1px)!important;
    .dropdown-item {
        display: flex;
        border: none;
        align-items: center;
        font-weight: 700 !important;
        color: #000 !important;
        font: 22px 'Book Antiqua';
        height: 100%;
        padding: 0 0 10px;
        @media (max-width: 536px) {
            font-size: 14px;
        }
        &:hover {
            color: $green!important;
            background: transparent!important;
        }
        &:focus {
            color: $green!important;
            background: transparent!important;
        }
    }
    .dropdown-menu {
        padding: 10px 0 0;
        .dropdown-item {
            color: #777;
            &::before {
                font: var(--fa-font-solid);
                vertical-align: middle;
                font-size: 12px;
                padding-right: 10px;
                display: inline-block; // Suppress underlining of the separator in modern browsers
                color: $breadcrumb-divider-color;
                content: $breadcrumb-divider;
            }
        }
    }
}

.navbar-nav > .active > a,
.navbar-nav > .show > a {
    color: $green;
}


i.nav-icon.active,
i.nav-icon:hover {
    transition: color .5s ease-in-out;
    color: $green;
    cursor: pointer;
}

.usefull-links {
    background-color: $green;
    color: #FFF;

    h3 {
        font-size: 1.625em;
        font-weight: 600;
    }

    p {
        font-size: 1.3125em;
    }

    a {
        color: #FFF;
        font-weight: 600 !important;
    }

    .readmore:after {
        color: #FFF;
    }

}

.imprint:before {
    font: var(--fa-font-solid);
    content: "\f0a9";
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
    font-weight: 600;
    color: $green;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
}

.side-links {
    background-color: #f5f5f5;
    padding-top: 40px;
    align-items: start;
    align-content: start;

    @media (max-width: 990px) {
        li {
            font-size: 13px;
        }
    }

    li a {
        color: #777777;
        font-size: 1.375em;
        padding-top: 10px;
    }

    li a:before {
        font: var(--fa-font-solid);
        content: "\f0a9";
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
        font-weight: 600;
        color: $green;
        font-size: 18px;
    }

    img {
        width: 60%;
        max-width: fit-content;

    }

    .side-links-partners {
        padding-top: 40px;
        max-width: 100%;
    }
}

.dropdown-menu {
    padding: 10px 20px 0;

    @media (max-width: 1650px) {
        font-size: 15px;
    }

    @media (max-width: 1400px) {
        font-size: 14px;
    }

    .nav-item {
        padding-bottom: 10px;

        .nav-link {
            padding: 0;
        }
    }
}

@media (max-width: 767.98px) {

    .popover,
    .dropdown-menu {
        width: 100%;
        left: 0;
    }
    .footer-nav img {
        margin: auto;
        display: block;
    }
}
@media (min-width: 1200px) {
    .container {
        zoom: 75%;
    }
}
.header {
    li {
        a {
            color: $green;
        }

        border-right: #cbcbcb solid 1px;
        padding-inline: 1.5em;
        padding-block: 0;
        margin-block: 0;
    }

    li:last-child {
        border: none;
        @media(min-width: 1200px) {
            padding-right: 0;
        }
    }

    .fa-search {
        cursor: pointer;
    }

    .fis {
        width: 40px;
        height: 30px;
        margin-inline: 8px;
        cursor: pointer;
    }
}

.nav-item {

    .nav-link {
        font-weight: bold !important;
    }
}

.nav-item {

    .nav-link {
        background: transparent;
        border-top: transparent solid 3px;
    }

    .nav-link:hover {
        color: $green !important;
    }
}

.navbar-nav > .active > .nav-link,
.navbar-nav > .show > .nav-link {
    color: $green !important;
}


.select2-selection--multiple {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    cursor: text;
    min-height: 22px;
    overflow: auto !important;
}

.select2-selection.select2-selection--single {
    height: 38px;
    border: 1px solid #ced4da;

    .select2-selection__rendered {
        line-height: 38px;
    }

    .select2-selection__arrow {
        height: 38px;
    }
}

.bootstrap-select .dropdown-menu {
    position: absolute;
}

.slick-slider {
    max-width: 100%;
}

#magazines-slider {
    .slick-list {margin: 0 -8px;}
    .slick-slide>div {padding: 0 8px;}
}

.fixed-top {
    width: 1720px;
    max-width: 100% !important;
    margin: auto;
    @media (max-width: 1800px) {
        width: calc(95% - 30px);
    }
    @media (max-width: 800px) {
        margin: 0;
        width: 100%;
    }
}


#navbar_top {
    background: white;
    transition: top 0.2s;
    @media screen and (max-width: 1200px) {
        .navbar-collapse {
            max-height: 100%;
        }
    }
}

.select2-selection.select2-selection--single .select2-selection__rendered {
    padding: 1.5em;
    background-color: #f4f3f3;
    font-size: 1.375em;
    height: 10px;
    line-height: 0;
}

.select2-selection.select2-selection--single {
    border: 0;
    height: 50px;
}

.select2-selection.select2-selection--single .select2-selection__arrow {
    height: 50px;
}

.procurements .checkboxes label {
    font-size: 1.375em;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 30px;
}

.procurements .checkboxes input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Hide the browser's default checkbox */
.procurements .checkboxes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.procurements .checkboxes label input ~ .checkmark {
    background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.procurements .checkboxes label input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.procurements .checkboxes label .checkmark:after {
    left: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
    background-color: $green;
}

.modal-dialog {
    box-shadow: none !important;
}

.hide-pointer *:before {
    content: "" !important;
}
.hide-pointer:before {
    content: "" !important;
}
.hide-pointer:hover {
    text-decoration: none !important;
}
